'use client';
import React, { useState } from 'react';
import { general as generalConf } from '@/config/general';
import Image from 'next/image';
import LogoImg from '@/assets/logonew.png';
import flag from '@/assets/new-assets/indianflag.webp';
import StoreDroidImg from '@/assets/store-droid.webp';
import StoreAppleImg from '@/assets/store-apple.webp';
import { useMobileResponsive } from '@/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';

const INFO_LINKS = [
  // { url: `/cookie-policy`, title: `Cookie Policy` },
  { url: `/privacy-policy`, title: `Privacy Policy` },
  { url: `/terms-and-conditions`, title: `Terms & Conditions` },
  { url: `/copyright-disclaimer`, title: `Copyright Disclaimer` },
  {
    url: `/cancellation-and-refund-policy`,
    title: `Cancellation & Refund Policy`,
  },
  // {
  //   url: `/course`,
  //   title: `LSM course`,
  // },
  {
    url: `mailto:support@level.game?subject=I need help with ...`,
    title: `Help`,
  },
];

const CROSS_PAGE_LINKS = [
  {
    url: generalConf.workWithUsMailToLink,
    title: `Work with Us`,
    linkType: `CAREER`,
  },
  { url: `/about-us.pdf`, title: `About Us` },
  { url: `/team`, title: `Meet the Team` },
  { url: `/blogs`, title: `Our Blog` },
  { url: `/faq`, title: `FAQs (Meditations)` },
  { url: `/faq/general`, title: `FAQs (General)` },
  { url: `https://discord.gg/9HuPmnFYQw`, title: `Join Our Discord` },
  {
    title: `Our Work Culture`,
    url: `https://bubbly-bayberry-48e.notion.site/Our-Work-Culture-3383acddc5a14b1faacdb6f2a4b08282`,
  },
];

const CONTACT_INFO = [
  <a key={1} href="mailto:support@level.game">
    Mail Us
  </a>,
  `Office No. 313, 3rd Floor, C Wing, Trade World Kamala Mills Compound, Senapati Bapat Marg, Lower Parel, Mumbai, Maharashtra 400013`,
  `Phone No.: +91 9082757997`,
];

export function Footer() {
  const router = useRouter();

  //Check if the current path is in the hiddenPaths array

  const year = new Date().getFullYear();
  const [isOpenOurCompany, setIsOpenOurCompany] = useState(false);
  const [isOpenCrossPageLinks, setIsOpenCrossPageLinks] = useState(false);
  const [isOpenContactInfo, setIsOpenContactInfo] = useState(false);

  const { isMobile } = useMobileResponsive();

  const hiddenPaths: string[] = [`/course`, `/course/successcourselevel`];

  const shouldHideFooter = hiddenPaths.includes(router.pathname);

  if (shouldHideFooter) {
    return null;
  }

  const toggleAccordionOurCompany = () => {
    setIsOpenOurCompany(!isOpenOurCompany);
  };

  const toggleAccordionCrossPageLinks = () => {
    setIsOpenCrossPageLinks(!isOpenCrossPageLinks);
  };

  const toggleAccordionContactInfo = () => {
    setIsOpenContactInfo(!isOpenContactInfo);
  };

  return (
    <footer>
      <div
        className="block md:flex md:justify-between items-center p-5 gap-8"
        style={{ background: `#282234`, color: `#CDCDCD` }}
      >
        <div className="w-72 pb-5 md:-mt-20 -mt-0 pl-2 p-4 px-1  ml-0 mr-auto">
          {/* <div className="w-72 md:w-[30%] py-5 pl-2 p-4 px-10 ml-0 mr-auto"> */}
          <Image
            src={LogoImg.src}
            width={LogoImg.width}
            height={LogoImg.height}
            placeholder="blur"
            blurDataURL={LogoImg.blurDataURL}
            alt="Level Logo"
          />

          <div className="text-sm mb-3 py-6">
            Download the app by clicking the link below:
          </div>

          <div className="flex md:gap-2 gap-6  justify-center items-center mt-3">
            <a
              href="https://lsm.lvl.fit?data=guya8119qpa1psiuzwii"
              className="border border-[#C4C4C4] bg-black rounded-md p-1"
            >
              <img
                src={StoreDroidImg.src}
                alt="download level app"
                width={StoreDroidImg.width}
                height={StoreDroidImg.height}
              />
            </a>

            <a
              href="https://lsm.lvl.fit?data=guya8119qpa1psiuzwii"
              className="border border-[#C4C4C4] bg-black rounded-md p-1"
            >
              <img
                src={StoreAppleImg.src}
                alt="download level app"
                width={StoreAppleImg.width}
                height={StoreAppleImg.height}
              />
            </a>
          </div>
        </div>

        <div className="w-full ">
          {isMobile ? (
            <div
              className={[
                `grid`,
                `gap-7`,
                `md:gap-0`,
                `md:grid-cols-3`,
                `grid-cols-1`,
                `max-w-7xl`,
                `m-auto`,
                `pt-7`,
              ].join(` `)}
            >
              {/* Accordion for "Our Company" */}
              <div className="w-full md:w-[30%]">
                <div>
                  <strong
                    onClick={toggleAccordionOurCompany}
                    className="flex justify-between items-center  text-xl font-extrabold cursor-pointer"
                  >
                    Our Company
                    <button className="md:hidden" aria-label="Name">
                      {isOpenOurCompany ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </button>
                  </strong>
                  {/* Show/hide the list items based on the isOpen state */}
                  {isOpenOurCompany &&
                    INFO_LINKS.map((f) => (
                      <div key={f.url} className="p-2">
                        <a href={f.url} target="_blank" rel="noreferrer">
                          {f.title}
                        </a>
                      </div>
                    ))}
                </div>
              </div>

              {/* Accordion for "Cross Page Links" */}
              <div className="w-full ">
                {isMobile}
                <div>
                  <strong
                    onClick={toggleAccordionCrossPageLinks}
                    className="flex justify-between items-center  text-xl font-extrabold cursor-pointer"
                  >
                    Cross Page Links
                    <button className="md:hidden" aria-label="Name">
                      {isOpenCrossPageLinks ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </button>
                  </strong>
                  {/* Show/hide the list items based on the isOpen state */}
                  {isOpenCrossPageLinks &&
                    CROSS_PAGE_LINKS.map((f) => (
                      <div key={f.url} className="p-2">
                        <a href={f.url} target="_blank" rel="noreferrer">
                          {f.title}
                        </a>
                      </div>
                    ))}
                </div>
              </div>

              {/* Accordion for "Contact Info" */}
              <div className="w-full">
                <div>
                  <strong
                    onClick={toggleAccordionContactInfo}
                    className="flex justify-between items-center text-xl font-extrabold cursor-pointer"
                  >
                    Contact Info
                    <button className="md:hidden" aria-label="Name">
                      {isOpenContactInfo ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </button>
                  </strong>
                  {/* Show/hide the list items based on the isOpen state */}
                  {isOpenContactInfo &&
                    CONTACT_INFO.map((f, i) => (
                      <div key={i} className="p-2">
                        {f}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <div
              className={[
                `grid`,
                `gap-10`,
                `md:gap-0`,
                `md:grid-cols-3`,
                `grid-cols-1`,
                `max-w-7xl`,
                `m-auto`,
                `px-0`,
                `py-16`,
              ].join(` `)}
            >
              <ul>
                {INFO_LINKS.map((f) => (
                  <li key={f.url} className="p-2">
                    {/* eslint-disable-next-line */}
                    <a href={f.url} target="_blank" rel="noreferrer">
                      {f.title}
                    </a>
                  </li>
                ))}
              </ul>

              <ul>
                {CROSS_PAGE_LINKS.map((f) => (
                  <li
                    key={f.url}
                    className={[
                      `p-2`,
                      f.linkType === `CAREER`
                        ? `inline-block px-[0.5em] pt-[0.3em] pb-[0.2em] font-bold bg-yellow-600 rounded-md mb-[0.4em]`
                        : ``,
                    ].join(` `)}
                  >
                    {/* eslint-disable-next-line */}
                    <a target="_blank" href={f.url} rel="noreferrer">
                      {f.title}
                    </a>
                  </li>
                ))}
              </ul>

              <ul>
                {CONTACT_INFO.map((f, i) => (
                  <li key={i} className="p-2">
                    {f}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      <div
        style={{ background: `#362E48`, color: `#CDCDCD` }}
        className="p-4 text-center  flex justify-between items-center"
      >
        <div>&copy; {year} Level Fittech Pvt. Ltd.</div>

        {!isMobile && (
          <div className="flex  items-center">
            Made Mindfully in
            <img className="pl-1" src={flag.src} alt="indian flag" />
          </div>
        )}

        {/* {!isMobile && (
          <div>
            <ul className="flex space-x-4">
              <li>Cookie Policy</li>
              <li>Privacy Policy</li>
              <li>Terms & Conditions</li>
            </ul>
          </div>
        )} */}
      </div>
    </footer>
  );
}
